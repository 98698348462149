@import '../../../styles/constants.scss';

.wiset-colorpicker {
  display: flex;

  > div {
    margin-top: $size-sm;
    display: flex;
    align-items: center;
    transition: border-bottom 200ms linear;
    width: 100%;

    > i {
      display: flex;
      justify-content: center;
      font-size: $font-xl;
      padding-left: $size-sm;
      transition: color 200ms linear;
      align-self: center;
      overflow: visible;
      z-index: 1;
      margin-right: $size-md;
    }
    > i:not(.primary-color) {
      color: $gray;
    }

    .wiset-colorpicker-placeholder {
      margin: 0;
      font-size: $font-lg;
      transition: linear all 200ms;
      text-align: left;
      overflow: visible;
      white-space: nowrap;
      margin-top: -8px;
      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .wiset-colorpicker-placeholder.with-input {
      justify-content: flex-end;
    }

    .color-input {
      padding: 0;
      border: 0;
      outline: 0;
      background-color: transparent;
      height: $size-xl;
      width: $size-xl;
    }
  }
}
.wiset-colorpicker.with-input {
  > div {
    justify-content: space-between;

    .wiset-input {
      width: 100%;
      padding-right: $size-md;
    }
  }
}
.wiset-colorpicker.error {
  > div {
    color: $color-error;

    p.placeholder,
    i {
      color: $color-error;
    }
  }
}
.wiset-colorpicker.with-icon:not(.with-input) {
  > div {
    height: 40px;

    .wiset-colorpicker-placeholder {
      margin: $size-sm $size-md 0 0;
      color: $gray;
    }

    input:not(.color-input) {
      margin-right: $size-sm;
    }
  }
}
.wiset-colorpicker.error:not(.with-input) {
  > div {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $color-error;

    .wiset-colorpicker-placeholder {
      color: $color-error;
    }
  }
}
