@import '../../../styles/constants.scss';

.collapsable-box {
  max-width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: ease-in-out overflow 0.25s 0.25s, ease-in-out all 0.25s;

  .expand-button {
    z-index: 1;
    border-radius: $size-sm $size-sm 0 0;

    i {
      transition: ease-in-out all 0.25s;
    }
  }
  .expand-button.left {
    align-self: flex-start;
  }
  .expand-button.center {
    align-self: center;
  }
  .expand-button.right {
    align-self: flex-end;
  }

  .collapsable-box-container {
    z-index: 0;
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 100%;
    padding: 0 $size-md;
    transition: ease-in-out max-height 0.25s, ease-in-out all 0.25s 0.25s;
    pointer-events: none;
    overflow-y: hidden;
    border: 1px solid $gray;
    border-bottom-color: transparent;
    max-height: 0;
    margin-top: -1px;

    * {
      opacity: 0;
      max-height: 0;
      transition: ease-in-out max-height 0.25s, ease-in-out all 0.25s 0.25s;
    }
  }
}
.collapsable-box.expanded {
  transition: ease-in-out overflow 0.25s 0.25s, ease-in-out all 0.25s;

  .expand-button {
    i {
      transform: rotateZ(-180deg);
    }
  }

  .collapsable-box-container {
    padding: $size-md;
    pointer-events: all;
    max-height: 999px;
    transition: ease-in-out max-height 0.25s 0.25s, ease-in-out all 0.25s;
    border-bottom-color: $gray;

    * {
      opacity: 1;
      max-height: 999px;
      transition: ease-in-out max-height 0.25s 0.25s, ease-in-out all 0.25s;
    }
  }
}
// INVERTED
.collapsable-box.inverted {
  flex-direction: column-reverse;
  .expand-button {
    border-radius: 0 0 $size-sm $size-sm;
  }

  .collapsable-box-container {
    border-top-color: transparent;
    border-bottom-color: $gray;
    margin-top: 0;
    margin-bottom: -1px;
  }
}
.collapsable-box.inverted.expanded {
  .collapsable-box-container {
    border-top-color: $gray;
  }
}

@media screen and (max-width: $screen-xl) {
  .collapsable-box {
    .expand-button:not(.right) {
      left: $size-md;
    }
  }
}
