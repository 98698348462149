@import '../../../styles/constants.scss';

.wiset-audio {
  display: flex;
  flex-direction: column;
  border-radius: $size-sm;

  > .wiset-audio-volume {
    > .btn {
      height: 100%;
      border-radius: 0;
      padding: $size-md;
      border-radius: 0 $size-sm $size-sm 0;
    }
  }

  .wiset-audio-volume {
    display: flex;
    flex-direction: column;

    .wiset-audio-volume-bar {
      max-height: 0;
      padding: 0 $size-md;
      transition: ease-in-out all 0.25s;
      border-radius: $size-sm $size-sm 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      > div {
        width: $size-md;
        height: 0;
        background-color: $white;
        transition: ease-in-out all 0.25s;
        display: flex;
        align-items: flex-end;

        > div {
          opacity: 0.5;
          width: $size-md;
          transition: linear all 0.25s;
          height: 0;
        }
      }
      .mute-btn {
        height: 0;
        padding: 0;

        i {
          width: $size-lg;
        }
      }
    }
    .wiset-audio-volume-bar.with-mute.expanded {
      margin-top: -90px;
      padding-top: 0;
    }

    .wiset-audio-volume-bar.expanded {
      max-height: 200px;
      margin-top: -68px;
      margin-bottom: -$size-xs;
      padding-top: $size-md;
      -webkit-box-shadow: 0px $size-sm $size-sm 0px rgba($black, 0.5);
      -moz-box-shadow: 0px $size-sm $size-sm 0px rgba($black, 0.5);
      box-shadow: 0px $size-sm $size-sm 0px rgba($black, 0.5);

      > div {
        height: 60px;
      }
      .mute-btn {
        height: $size-xl;
        padding: $size-md 0;
      }
    }
  }

  .wiset-audio-btns {
    display: flex;

    .btn {
      flex: 1;
      padding: $size-md;
      border-radius: 0;
      border: 0;
    }

    > *:first-child {
      border-radius: $size-sm 0 0 0;
    }
    > *:last-child,
    > *:last-child > .btn {
      border-radius: 0 $size-sm 0 0;
    }
  }
  .wiset-audio-container {
    display: flex;
    flex-direction: column;
    padding: $size-md;
    border-radius: 0 0 $size-sm $size-sm;

    .wiset-audio-progress-bar {
      width: 100%;
      height: $size-md;
      min-width: 150px;
      background-color: $white;

      > div {
        height: $size-md;
        opacity: 0.4;
        transition: linear all 0.25s;
      }
    }
    .wiset-audio-measure {
      margin-top: $size-md;
      color: $black;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
.wiset-audio-small {
  flex-direction: row;
  .wiset-audio-container {
    border-radius: 0;
    .wiset-audio-progress-bar {
      min-width: 100px;
    }
  }
  .wiset-audio-container.without-volume {
    border-radius: 0 $size-sm $size-sm 0;
  }
  .wiset-audio-btns {
    .btn,
    > *:first-child,
    > *:last-child,
    > *:last-child > .btn {
      border-radius: $size-sm 0 0 $size-sm;
    }
  }
}
