@import '../../../styles/constants.scss';

#main-container {
  .page {
    > div.page-background {
      padding: $size-xl;
      background-color: $background-gray;
    }
  }
  .page:not(.page-sidebar):not(.page-large-sidebar),
  .page.page-navbar {
    padding-top: 70px;
  }
  .page.page-sidebar {
    padding-left: 80px;
  }
  .page.page-large-sidebar {
    padding-left: 150px;
  }
}

@media screen and (max-width: $screen-xl) {
  #main-container {
    padding-top: 70px;
    .page {
      padding-top: 0px;

      > div.page-background {
        padding: $size-lg;
      }
    }
    .page:not(.page-sidebar):not(.page-large-sidebar),
    .page.page-navbar {
      padding-top: 0px;
    }
    .page.page-sidebar,
    .page.page-large-sidebar {
      padding-left: 0px;
    }

    &.without-navbar {
      padding-top: 0;
    }
  }
}
