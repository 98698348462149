@import '../../../styles/constants.scss';

.wiset-amount-selector {
  display: flex;
  align-items: center;
  border-radius: $size-sm;
  overflow: hidden;
  height: 32px;

  .btn {
    margin: 0;
    border-radius: 0;
    height: 34px;
  }

  > p {
    height: 30px;
    min-width: 40px;
    margin: 0;
    padding: 0 6px;
    text-align: center;
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
