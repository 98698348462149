@import '../../../styles/constants.scss';

#backoffice-shop {
  flex: 1;
  .page-background {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .shop-card {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    border-radius: $size-sm;
    padding: $size-md;
    -webkit-box-shadow: 0px 0px $size-sm $size-sm rgba($gray, 0.25);
    -moz-box-shadow: 0px 0px $size-sm $size-sm rgba($gray, 0.25);
    box-shadow: 0px 0px $size-sm $size-sm rgba($gray, 0.25);
    min-width: 512px;
    min-height: 512px;
  }

  .image-dropzone {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    background-color: $white;
    margin: $size-sm;

    i {
      font-size: 40px;
      color: $primary;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .image-preview {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    background-color: $white;
    margin: $size-sm;

    img {
      object-fit: contain;
      width: 100%;
    }

    .btn {
      position: absolute;
      top: 10px;
      right: 10px;
      color: red;
      z-index: 1;
    }
  }

  .shop-name {
    flex: 0;
    margin: 0 $size-sm;
  }

  .save-shop{
    margin: $size-md $size-sm 0 $size-sm;
  }
}