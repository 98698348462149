@import '../../../styles/constants.scss';

.info-container {
  .info {
    display: flex;
    align-items: center;
    background-color: $color-info;
    border: 1px solid $color-info-border;
    border-radius: $size-sm;
    color: $white;

    > i {
      padding: $size-md $size-lg;
      font-size: $font-title;
    }

    > div {
      border-left: 1px solid $color-info-border;
      padding: $size-md $size-lg;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
