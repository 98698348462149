@import '../../../styles/constants.scss';

.backoffice-card {
  padding: $size-lg;

  > .btn {
    padding: 0;
    border: 0;
    display: flex;
    align-items: flex-end;
    border-radius: $size-sm;
    min-height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-box-shadow: 0px 0px $size-sm $size-sm rgba($gray, 0.25);
    -moz-box-shadow: 0px 0px $size-sm $size-sm rgba($gray, 0.25);
    box-shadow: 0px 0px $size-sm $size-sm rgba($gray, 0.25);

    .backoffice-card-title {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      border-radius: 0 0 $size-sm $size-sm;
      width: 100%;
      height: 75px;
      font-size: $font-md;
      background-color: transparent;
      transition: ease-in-out all $transition-mid;
      background-color: rgba($primary, 0.5);
    }
  }
  > .btn.disabled {
    background-blend-mode: color-burn;
    .backoffice-card-title {
      color: $gray;
      background-color: rgba($primary-darker, 0.5);
    }
  }
  > .btn:not(.disabled):hover {
    .backoffice-card-title {
      height: 150px;
      font-size: $font-xl;
      background-color: rgba($primary, 0.75);
    }
  }
}

@media screen and (max-width: $screen-lg) {
  .backoffice-card {
    padding: $size-md;
  }
}
