@import '../../../styles/constants.scss';

.wiset-checkbox-container {
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;
  font-family: 'Arial', sans-serif;
  cursor: pointer;

  .wiset-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 24px;
    min-width: 24px;
    border-radius: $size-sm;
    transition: linear all 0.2s;

    i {
      color: $white;
    }
  }
  .wiset-checkbox.inactive {
    background-color: $gray-light;
  }

  span {
    margin: 0 $size-sm;
  }
}

.wiset-checkbox-container.wiset-switch-container {
  .wiset-checkbox.wiset-switch {
    min-width: 40px;
    border-radius: $size-lg;
    justify-content: flex-start;
    padding: 0 $size-sm;
    background-color: $gray-light;

    .wiset-switch-inner {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      transition: linear all 0.2s;
    }
    .wiset-switch-inner.inactive {
      background-color: $white;
    }
    .wiset-switch-inner:not(.inactive) {
      margin-left: 12px;
    }
  }

  .switch-text-checked {
    font-weight: bold;
    transition: linear all 0.2s;
  }
}
