.wiset-modal-container {
  .wiset-modal.sure-modal {
    width: 95%;
    max-width: 600px;

    .wiset-modal-body {
      text-align: center;
    }

    .sure-text {
      margin-bottom: 0;
    }
  }
}
