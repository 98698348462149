@import '../../../styles/constants.scss';

#backoffice-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: $background-gray;

  .cards-full {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 90%;

    > .backoffice-card {
      width: 100%;
    }
  }

  .cards-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 90%;

    > .backoffice-card {
      flex: 0 25%;
    }
  }
}

@media screen and (max-width: $screen-lg) {
  #backoffice-container {
    .cards-container {
      > .backoffice-card {
        flex: 0 33.3333%;
      }
    }
  }
}

@media screen and (max-width: $screen-md) {
  #backoffice-container {
    .cards-container {
      > .backoffice-card {
        flex: 0 50%;
      }
    }
  }
}

@media screen and (max-width: $screen-sm) {
  #backoffice-container {
    .cards-container {
      > .backoffice-card {
        flex: 0 100%;
      }
    }
  }
}
