@import '../../../styles/constants.scss';

.generic-backoffice {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  background-color: $background-gray;

  .backoffice-shadow {
    -webkit-box-shadow: 0px 0 $size-lg $size-sm rgba($gray-light, 0.3);
    -moz-box-shadow: 0px 0 $size-lg $size-sm rgba($gray-light, 0.3);
    box-shadow: 0px 0 $size-lg $size-sm rgba($gray-light, 0.3);
  }

  .generic-backoffice-body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 1500px;
    width: 95%;

    .generic-backoffice-filters {
      flex: 1;
      padding: 0 $size-lg $size-lg $size-lg;
    }

    .generic-backoffice-table,
    > .alert-container {
      flex: 3;
      padding: 0 $size-lg $size-lg $size-lg;
    }
  }
}

@media screen and (max-width: $screen-xl) {
  .generic-backoffice {
    .generic-backoffice-body {
      flex-direction: column;

      .generic-backoffice-filters,
      .generic-backoffice-table {
        flex: 1;
        width: 100%;
      }
    }
  }
}
