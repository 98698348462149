@import '../../../styles/constants.scss';

.wiset-cards-slider {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;

  .slider-btns {
    > button:focus {
      outline: none;
    }

    .slider-btn {
      padding: 0;
      margin: 0;
      font-size: $font-xl;
      text-align: center;
      color: $primary;
      width: 45px;
      height: 100%;
      border: 0;
      cursor: pointer;
      position: absolute;
      z-index: 2;
      transition: all ease-in-out 0.2s;
      background-color: rgba($white, 0.75);
      border-radius: 0;
    }

    .slider-btn:hover {
      color: $primary-darker;
      font-weight: bold;
      font-size: $font-xl;
    }
  }
  .slider-btns.bottom-btns {
    display: flex;
    justify-content: center;
    margin-top: $size-lg;

    .slider-btn {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin: 0 $size-lg;
      background-color: transparent;
      color: $gray;
      border: 1px solid $gray-light;
      position: inherit;
    }
    .slider-btn:hover {
      font-size: $font-xl;
      color: $gray;
      border: 1px solid $gray;
    }
  }

  .btn-r {
    right: 0;
  }
  .btn-l {
    left: 0;
  }

  .cards {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    max-height: 100%;
    width: 100%;
    padding: 0;

    .card-in-slider {
      height: 100%;
      text-align: left;
      display: inline-block;
      left: 0;
      padding: 0;
      transition: transform 0.7s ease-in-out;

      > * {
        width: 100%;
      }
    }
  }

  .dots {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .element-dot {
      border: 2px solid $primary;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      margin: $size-sm;
      padding: 0;
    }
  }
}

.wiset-cards-slider.show-prev-next {
  display: inline-block;
  position: sticky;
  padding: 0 45px;
}

/* Fading animation */
.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
