@import '../../../styles/constants.scss';

.wiset-selector {
  .wiset-selector-input {
    display: flex;
    align-items: center;

    .wiset-input {
      width: 100%;
      margin-right: -($size-lg);
    }
    .wiset-input.disabled {
      > div:not(.primary-border) {
        border-bottom-color: $gray-light;
        input {
          color: $black;
        }
      }
    }
    .wiset-input.with-clear {
      margin-right: -($size-xl);
    }

    .wiset-selector-btns {
      display: flex;
      background-color: $white;
      height: 30px;

      > .btn {
        width: $size-lg;
        padding: 0;
        border: 0;
        color: $gray;
        z-index: 2;
      }

      .clear-btn {
        opacity: 0;
        color: $black;
      }
      .clear-btn.visible {
        opacity: 1;
      }

      .wiset-selector-arrow.invert {
        transform: rotate(-180deg);
      }
    }
  }

  .wiset-selector-options {
    position: absolute;
    max-height: 0px;
    background-color: $white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: flex-start;
    transition: linear all 0.2s;
    z-index: 3;

    .wiset-selector-option {
      width: 100%;
      text-align: left;
      transition: linear all 0.2s;
      border-radius: 0;
      color: $black;
      justify-content: flex-start;

      > p {
        padding: 0 $size-md;
      }
    }

    .empty-options-text {
      width: 100%;
      text-align: left;
      border-radius: 0;
      color: $black;
      justify-content: flex-start;
      padding: $size-md $size-lg;
    }
  }
  .wiset-selector-options.visible {
    max-height: 200px;
    overflow: auto;
    border-radius: 0 0 $size-sm $size-sm;
    -webkit-box-shadow: 0px $size-sm $size-sm $size-sm rgba($black, 0.15);
    -moz-box-shadow: 0px $size-sm $size-sm $size-sm rgba($black, 0.15);
    box-shadow: 0px $size-sm $size-sm $size-sm rgba($black, 0.15);
    .wiset-selector-option:hover:not(.primary-lighter-bg),
    .wiset-selector-option.focused:not(.primary-lighter-bg) {
      background-color: rgba($gray-light, 0.25);
    }
    .wiset-selector-option:hover.primary-lighter-bg,
    .wiset-selector-option.focused.primary-lighter-bg {
      opacity: 0.9;
    }
  }
}
.wiset-selector.disabled {
  opacity: 0.5;
  .wiset-selector-input .wiset-input.disabled > div:not(.primary-border) {
    border-bottom-color: transparent;
  }
  * {
    cursor: no-drop;
  }
}
