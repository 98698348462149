@import './constants.scss';

body {
  font-family: 'Montserrat', sans-serif;
  font-size: $font-md;
  letter-spacing: -0.5px;
  margin: 0;
  color: $black;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;

  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .p-link:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .p-inputtext:enabled:focus {
    -webkit-box-shadow: inherit;
    -moz-box-shadow: inherit;
    box-shadow: inherit;
    border-color: inherit;
  }
  .p-component {
    font-family: 'Montserrat', sans-serif;
    font-size: $font-lg;
    color: $black;
  }
  .p-disabled {
    cursor: not-allowed;
  }
}

#main-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $primary;

  .loading {
    height: 200px;

    .loading-container {
      max-height: 200px;
      max-width: 150px;
    }
  }
}

.shadow {
  -webkit-box-shadow: 0px 0 $size-lg $size-sm rgba($gray-light, 0.3);
  -moz-box-shadow: 0px 0 $size-lg $size-sm rgba($gray-light, 0.3);
  box-shadow: 0px 0 $size-lg $size-sm rgba($gray-light, 0.3);
}

.not-found-page {
  margin-top: 70px;
}

.hide-content {
  opacity: 0;
}

.generic-backoffice-table {
  .wiset-table {
    tbody {
      tr {
        td {
          text-align: center;
          i.fa-check {
            color: $color-success;
          }
          i.fa-times {
            color: $color-error;
          }
        }
      }
    }
  }
}

#main-container {
  > .wiset-selector {
    display: none;
    background-color: $background-gray;
    width: 100%;
    margin: 0 auto;
    padding: $size-lg 0;
    overflow: visible;
    .wiset-input {
      > div {
        border: 0;

        input {
          -webkit-box-shadow: 0px 0 $size-lg $size-sm rgba($gray-light, 0.3);
          -moz-box-shadow: 0px 0 $size-lg $size-sm rgba($gray-light, 0.3);
          box-shadow: 0px 0 $size-lg $size-sm rgba($gray-light, 0.3);
        }
      }
    }
    input,
    .wiset-selector-options {
      max-width: 300px;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: $screen-xl) {
  #main-container {
    > .wiset-selector {
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        padding: $size-md $size-lg;
        border-radius: $size-sm;
        background-color: $white;
      }
    }
  }
}

#main-container.backoficce {
  min-height: auto;
  display: flex;
  margin-bottom: $size-lg;

  > div:not(.main-sidebar) {
    margin: 0 auto;
    width: 100%;
  }
}

#main-container,
main {
  height: 100%;
  min-height: 100vh;
  display: flex;
}

main {
  display: flex;
  flex-direction: column;
  background-color: $background-gray;
}

.center-text {
  text-align: center;
}
.right-text {
  text-align: right;
}
.center-content {
  margin: 0 auto;
}

// CONTAINER
.container {
  max-width: 1300px;
  width: 95%;
  margin: 0 auto;
}

// inputs y textareas
input,
textarea {
  font-family: 'Montserrat', sans-serif;
  color: $black;
}

// tables
.wiset-table-header {
  color: $white;
  background: $primary;
  font-weight: 400;
  margin: 00;
  padding: $size-md;
  text-align: center;
}
.wiset-table.p-datatable {
  min-width: 600px;
  .p-datatable-thead {
    > tr {
      > th {
        border: 0;
        text-transform: uppercase;
        background-color: $gray-light;
      }
    }
  }
  .p-datatable-tbody {
    > tr {
      > td {
        border: 0;
        text-align: center;
        font-weight: 400;

        img {
          max-width: 50px;
          max-height: 50px;
        }

        .awarded-tags {
          display: flex;
          flex-direction: column;

          a {
            color: $primary;
            margin: 0 $size-xs;
          }
        }
      }
    }
    > tr:nth-child(even) {
      background-color: rgba($primary-light, 0.05);
    }
  }
  .p-paginator {
    border: 0;

    .p-highlight {
      background-color: $primary;
    }
  }
}

@media screen and (max-width: $screen-lg) {
  .wiset-table.p-datatable {
    .p-datatable-thead {
      > tr {
        > th {
          font-size: $font-sm;
        }
      }
    }
    .p-datatable-tbody {
      > tr {
        > td,
        > td * {
          font-size: $font-sm;
        }
      }
    }
  }
}

// Colors styles// PRIMARY
.primary-bg {
  background-color: $primary;
}
.primary-border {
  border-color: $primary;
}
.primary-color,
body a.primary-color:hover {
  color: $primary;
}
.primary-light-bg {
  background-color: $primary-light;
}
.primary-light-border {
  border-color: $primary-light;
}
.primary-light-color,
body a.primary-light-color:hover {
  color: $primary-light;
}
.primary-lighter-bg {
  background-color: $primary-lighter;
}
.primary-lighter-border {
  border-color: $primary-lighter;
}
.primary-lighter-color,
body a.primary-lighter-color:hover {
  color: $primary-lighter;
}
.primary-dark-bg {
  background-color: $primary-dark;
}
.primary-dark-border {
  border-color: $primary-dark;
}
.primary-dark-color,
body a.primary-dark-color:hover {
  color: $primary-dark;
}
.primary-darker-bg {
  background-color: $primary-darker;
}
.primary-darker-border {
  border-color: $primary-darker;
}
.primary-darker-color,
body a.primary-darker-color:hover {
  color: $primary-darker;
}
// SECONDARY
.secondary-bg {
  background-color: $secondary;
}
.secondary-border {
  border-color: $secondary;
}
.secondary-color,
body a.secondary-color:hover {
  color: $secondary;
}
.secondary-light-bg {
  background-color: $secondary-light;
}
.secondary-light-border {
  border-color: $secondary-light;
}
.secondary-light-color,
body a.secondary-light-color:hover {
  color: $secondary-light;
}
.secondary-lighter-bg {
  background-color: $secondary-lighter;
}
.secondary-lighter-border {
  border-color: $secondary-lighter;
}
.secondary-lighter-color,
body a.secondary-lighter-color:hover {
  color: $secondary-lighter;
}
.secondary-dark-bg {
  background-color: $secondary-dark;
}
.secondary-dark-border {
  border-color: $secondary-dark;
}
.secondary-dark-color,
body a.secondary-dark-color:hover {
  color: $secondary-dark;
}
.secondary-darker-bg {
  background-color: $secondary-darker;
}
.secondary-darker-border {
  border-color: $secondary-darker;
}
.secondary-darker-color,
body a.secondary-darker-color:hover {
  color: $secondary-darker;
}
// ACCENT
.accent-bg {
  background-color: $accent;
}
.accent-border {
  border-color: $accent;
}
.accent-color,
body a.accent-color:hover {
  color: $accent;
}
.accent-light-bg {
  background-color: $accent-light;
}
.accent-light-border {
  border-color: $accent-light;
}
.accent-light-color,
body a.accent-light-color:hover {
  color: $accent-light;
}
.accent-lighter-bg {
  background-color: $accent-lighter;
}
.accent-lighter-border {
  border-color: $accent-lighter;
}
.accent-lighter-color,
body a.accent-lighter-color:hover {
  color: $accent-lighter;
}
.accent-dark-bg {
  background-color: $accent-dark;
}
.accent-dark-border {
  border-color: $accent-dark;
}
.accent-dark-color,
body a.accent-dark-color:hover {
  color: $accent-dark;
}
.accent-darker-bg {
  background-color: $accent-darker;
}
.accent-darker-border {
  border-color: $accent-darker;
}
.accent-darker-color,
body a.accent-darker-color:hover {
  color: $accent-darker;
}
// GRAY
.gray-bg {
  background-color: $gray;
}
.gray-border {
  border-color: $gray;
}
.gray-color,
body a.gray-color:hover {
  color: $gray;
}
// WHITE
.white-bg {
  background-color: $white;
}
.white-border {
  border-color: $white;
}
.white-color,
body a.white-color:hover {
  color: $white;
}
// BLACK
.black-bg {
  background-color: $black;
}
.black-border {
  border-color: $black;
}
.black-color,
body a.black-color:hover {
  color: $black;
}
// WHITE
.white-bg {
  background-color: $white;
}
.white-border {
  border-color: $white;
}
.white-color,
body a.white-color:hover {
  color: $white;
}
// TRANSPARENT
.transparent-bg {
  background-color: transparent;
}
.transparent-border {
  border-color: transparent;
}
.transparent-color,
body a.transparent-color:hover {
  color: transparent;
}

// TABLE
.wiset-table-container {
  .wiset-table-overflow {
    .wiset-table {
      tbody {
        tr:nth-child(even) {
          background-color: rgba($primary-light, 0.1);
        }
      }
    }
  }
}
