@import '../../../../styles/constants.scss';

.wiset-tabs {
  .wiset-tab.btn {
    margin: 0;
    border-radius: $size-md $size-md 0 0;
    border: 1px groove rgba($white, 0.3);
    flex: 1;
    padding: $size-md $size-sm;

    span {
      font-size: $font-sm;
    }
    i.left.with-margin {
      font-size: $font-xl;
      margin-right: $size-md;
    }
  }
  // Selected
  .wiset-tab-selected.btn {
    padding: $size-lg $size-sm;
    flex: 1.2;

    span {
      font-size: $font-md;
      font-weight: bold;
    }
  }
  // Error
  .btn.wiset-tab-error,
  .btn.wiset-tab-error:hover {
    background-color: $color-error;
    border: 1px groove $color-error-border;
  }
  // Error selected
  .btn.wiset-tab-error.wiset-tab-selected,
  .btn.wiset-tab-error.wiset-tab-selected:hover {
    background-color: rgba($color-error, 0.8);
  }
}

@media screen and (max-width: $screen-md) {
  .wiset-tabs {
    .wiset-tab.btn {
      span {
        font-size: $font-xs;
      }
    }
    .wiset-tab-selected.btn {
      span {
        font-size: $font-sm;
      }
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .wiset-tabs {
    .wiset-tab.btn {
      i.left.with-margin {
        margin: 0;
      }
      span {
        font-size: 0;
      }
    }
    .wiset-tab-selected.btn {
      i.left.with-margin {
        margin-right: $size-sm;
      }
      span {
        font-size: $font-sm;
      }
    }
  }
}
