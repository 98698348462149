@import '../../../styles/constants.scss';

.wiset-select-btn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  > p {
    margin-bottom: $size-md;
    margin-top: 0;

    i {
      margin-right: $size-md;
    }
  }

  .btns-container {
    display: flex;

    > div {
      .btn {
        margin: 0;
        border-radius: 0;
      }
      .btn.selected {
        font-weight: bold;
        -webkit-box-shadow: inset 0 0 $size-md $size-xs rgba($black, 0.3);
        -moz-box-shadow: inset 0 0 $size-md $size-xs rgba($black, 0.3);
        box-shadow: inset 0 0 $size-md $size-xs rgba($black, 0.3);
      }
    }

    > div:first-child {
      .btn {
        border-radius: $size-sm 0 0 $size-sm;
      }
    }
    > div:not(:last-child) {
      margin-right: -1px;
    }
    > div:last-child {
      .btn {
        border-radius: 0 $size-sm $size-sm 0;
      }
    }

    .round {
      display: flex;
      align-items: center;

      .btn:not(.selected) {
        background: transparent;
      }
      .btn,
      .btn:first-child,
      .btn:last-child {
        margin-right: $size-sm;
        padding: $size-xs;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        border-width: 1px;
      }
      > span {
        margin-right: $size-md;
      }
    }
  }
}
