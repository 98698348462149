@import '../../../styles/constants.scss';

.wiset-dropzone {
  max-width: 250px;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  justify-content: flex-end;

  .wiset-dropzone-drop:not(.primary-bg) {
    background-color: $background-gray;
    border-color: $gray-lighter;
  }

  .cancel-edit-btn {
    padding: $size-md;
    display: flex;
    position: absolute;
    border: 0;
    background-color: rgba($white, 0.3);
  }
  .cancel-edit-btn.gray-color {
    align-self: flex-end;
    i {
      color: $gray;
    }
  }
  .cancel-edit-btn.error-color i {
    color: $color-error;
  }

  .wiset-dropzone-drop {
    height: 200px;
    width: 200px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-style: dashed;
    border-radius: $size-sm;
    transition: linear all 0.2s;
    > *:not(:first-child):not(.btn) {
      margin-top: $size-md;
    }
  }

  .file-input {
    display: none;
  }

  .wiset-dropzone-preview {
    min-height: 200px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    > .wiset-audio {
      margin-top: $size-xl;
      max-width: calc(100% - 16px);
    }

    .clear-images {
      padding: $size-md;
      display: flex;
      align-self: flex-end;
      position: absolute;
      border: 0;

      i {
        color: $color-error;
      }
    }

    img {
      margin-right: $size-md;
    }

    > p {
      border: 1px solid $gray-lighter;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: $background-gray;
      flex: 1;
      margin: 0;
    }

    .wiset-multi-dropzone {
      display: flex;
      flex-direction: column;
      width: 100%;

      .audio-multi {
        flex-direction: column-reverse;

        .wiset-audio {
          max-width: calc(100% - 16px);
        }
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $gray-lighter;
        margin-bottom: $size-md;
        background-color: $background-gray;
        padding: $size-md 0 $size-md $size-md;

        img {
          max-height: 50px;
          max-width: 50px;
        }

        > p {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .clear-multi {
          padding: $size-md;
          border: 0;

          i {
            color: $color-error;
          }
        }
      }
    }
  }
  .wiset-dropzone-preview.multi {
    min-height: inherit;
  }

  .wiset-dropzone-error {
    margin-top: $size-sm;
  }
}
.wiset-dropzone.disabled {
  .wiset-dropzone-drop {
    border-style: solid;
    background-color: rgba($gray-light, 0.5);
  }
  *:not(.with-value) {
    cursor: not-allowed;
    color: $gray-light;
  }
}

.wiset-dropzone:hover:not(.disabled) {
  .wiset-dropzone-drop {
    // background-color: $gray-light;
    // border-color: $gray;
    border-style: solid;
  }
}
