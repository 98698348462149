@import '../../../../styles/constants.scss';

.wiset-table-container .wiset-table-overflow .wiset-table thead tr th {
  height: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color linear 0.2s;
    height: 100%;

    > button {
      background-color: transparent;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      padding: $size-md $size-lg;
      width: 100%;
      height: 100%;
      font-size: $font-md;

      p {
        margin: 0;
      }
      p.with-sort {
        margin-right: $size-md;
      }
    }
    > button:not(:disabled) {
      cursor: pointer;
    }

    > input {
      margin-top: 0;
      margin-bottom: $size-md;
      background-color: $white;
      max-width: 70%;
      outline: 0;
      border: 0;
      padding: $size-sm $size-md;
      color: $black;
    }
  }
}
