@import '../../../styles/constants.scss';

.wiset-input {
  display: flex;
  flex-direction: column;

  .placeholder {
    margin: 0;
    font-size: $font-lg;
    transition: linear all 200ms;
    text-align: left;
    height: 0;
    width: 0;
    overflow: visible;
    white-space: nowrap;
    margin-top: -8px;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .placeholder:not(.primary-color) {
    color: $gray;
  }
  .placeholder.with-content {
    transform: translate(2px, -16px);
    font-size: $font-sm;
  }

  > div:not(.primary-border) {
    border-bottom-color: $gray-light;
  }
  > div {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: flex;
    align-items: center;
    transition: border-bottom 200ms linear;

    i,
    > span {
      display: flex;
      justify-content: center;
      font-size: $font-xl;
      margin-top: -10px;
      padding-left: $size-lg;
      transition: color 200ms linear;
      align-self: center;
      width: 0;
      height: 0;
      overflow: visible;
      z-index: 1;
    }
    .input-clear-btn,
    .input-show-pass-btn {
      background-color: transparent;
      border: 0;
      margin-left: -($size-xl);
    }
    .right-elem {
      padding-left: 0;
      padding-right: $size-lg;
    }
    i:not(.primary-color),
    > span:not(.primary-color) {
      color: $gray;
    }
    input,
    textarea {
      width: 100%;
      min-height: 20px;
      font-size: $font-lg;
      padding: $size-sm;
      padding-top: $size-lg;
      border: 0;
      outline-width: 0;
      background: transparent;
      transition: linear color 0.2s;
    }
    textarea {
      font-family: inherit;
      resize: none;
      overflow: auto;
      line-height: 20px;
    }
    textarea.vert {
      resize: vertical;
    }
    textarea.noResize {
      resize: none;
    }
  }

  .wiset-input-info {
    margin: 0;
    font-size: $font-sm;
    padding: 0px 5px;
    text-align: left;
    color: $gray;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .wiset-input-error {
    color: $color-error;
    margin: 0;
    font-size: $font-sm;
    padding: 0px 5px;
    text-align: left;
  }

  .wiset-input-counter {
    margin: 0;
    text-align: right;
    font-size: $font-md;
    color: $gray;
  }
}

.wiset-input.with-icon {
  .placeholder:not(.with-content) {
    transform: translate(32px, 0);
  }
  input,
  textarea {
    padding-left: $size-xl;
    margin-left: -15px;
  }
}

.wiset-input.error {
  > div {
    border-bottom: 1px solid $color-error;
    color: $color-error;

    input,
    textarea {
      background-color: transparent;
    }
    p.placeholder,
    i {
      color: $color-error;
    }
  }
}
.wiset-input.disabled {
  > div {
    border-color: transparent;
  }
  input,
  textarea {
    color: $gray;
  }
}
.wiset-input.wiset-textarea {
  > div {
    p.placeholder {
      align-self: flex-start;
      margin-left: 0;
      margin-top: $size-lg;
    }
    p.placeholder.with-content {
      background: $white;
      width: auto;
      position: absolute;
      height: 18px;
      padding-right: $size-md;
    }
  }
}

