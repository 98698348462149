// ***********
// COLORS
// ***********
$primary-lighter: #e5f3f3;
$primary-light: #c2e2e2;
$primary: #a8d6d6;
$primary-dark: #97cccc;
$primary-darker: #7dbcbc;

$secondary-lighter: #ffe2e8;
$secondary-light: #ffbbc8;
$secondary: #ff9eb1;
$secondary-dark: #ff8ca1;
$secondary-darker: #ff7088;

$accent-lighter: #fff8ec;
$accent-light: #ffefd2;
$accent: #ffe8be;
$accent-dark: #ffe2af;
$accent-darker: #ffd899;

// Gray
$gray: #888888;
$gray-light: #c0c0c0;
$gray-lighter: #d8d8d8;
$gray-border: 1px solid rgba($gray-light, 0.5);

// B&W
$background-gray: #f4f4f4;
$white: #ffffff;
$black: #282828;

// Error, info...
$color-error: #db341a;
$color-error-border: #ff745e;
$color-warning: #e8b125;
$color-warning-border: #ffef5e;
$color-info: $primary;
$color-info-border: $primary-light;
$color-success: #699c1c;
$color-success-border: #acde5b;

// ***********
// PADDING, MARGIN AND RADIUS
// ***********
$size-xs: 2px;
$size-sm: 4px;
$size-md: 8px;
$size-lg: 16px;
$size-xl: 32px;

// ***********
// TRANSITIONS
// ***********
$transition-slow: 0.5s;
$transition-mid: 0.25s;
$transition-fast: 0.1s;

// ***********
// FONT
// ***********
$font-xs: 8px;
$font-sm: 12px;
$font-md: 14px;
$font-lg: 16px;
$font-xl: 20px;
$font-subtitle: 24px;
$font-title: 32px;
$bold: 500;

// ***********
// SCREEN SIZES
// ***********
$screen-xs: 310px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
