// Define font

/** Regular font */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-Regular_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

/** Black font */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/Montserrat-Black.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-Black_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-Black.ttf') format('truetype');
}

/** Black-Italic font */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/Montserrat-BlackItalic.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-BlackItalic_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-BlackItalic.ttf') format('truetype');
}

/** Bold font */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-Bold_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
}

/** Bold-Italic font */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: bold;
  src: url('../assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-BoldItalic_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
}

/** ExtraBold font */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-ExtraBold_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
}

/** Bold-Italic font */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-BoldItalic_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
}

/** Italic font */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/Montserrat-Italic.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-Italic_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-Italic.ttf') format('truetype');
}

/** Light font */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-Light_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
}

/** Light-Italic font */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/Montserrat-LightItalic.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-LightItalic_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-LightItalic.ttf') format('truetype');
}

/** SemiBold font */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-SemiBold_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}

/** SemiBold-Italic font */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-SemiBoldItalic_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
}

/** Thin font */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/Montserrat-Thin.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-Thin_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-Thin.ttf') format('truetype');
}

/** Thin-Italic font */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/Montserrat-ThinItalic.ttf') format('truetype');
  // src: url('../assets/fonts/Montserrat-ThinItalic_IE.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Montserrat-ThinItalic.ttf') format('truetype');
}
