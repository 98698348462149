@import '../../../styles/constants.scss';

.wiset-datepicker {
  border-color: $primary;

  .wiset-input:not(.error),
  .wiset-input.disabled,
  .wiset-input:disabled {
    > div:not(.primary-border) {
      border-bottom-color: $gray-light;
      input {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .wiset-calendar,
  .wiset-month-selector {
    max-height: 0;
    opacity: 0;
    transition: ease-in-out all 0.25s;
    position: absolute;
    z-index: 3;
    pointer-events: none;
  }
  .wiset-calendar.visible,
  .wiset-month-selector.visible {
    max-height: 100vh;
    opacity: 1;
    pointer-events: all;
  }
}

@media screen and (max-width: $screen-lg) {
  .wiset-datepicker {
    .wiset-calendar {
      position: fixed;
    }
  }
}
