@import '../../../styles/constants.scss';

.tooltip-container {
  position: relative;
  display: inline-block;
  font-size: $font-sm;
  display: flex;
  align-items: center;
  justify-content: center;

  .tooltip-trigger {
    display: inline-block;
  }

  .tooltip {
    display: none;
    min-width: 100px;
    position: fixed;
    z-index: 10;
    &::after {
      content: '';
      position: absolute;
    }

    // MESSAGE
    .tooltip-message {
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba($black, 0.75);
      border-radius: $size-xs;
      color: $white;
      padding: $size-md;
      text-align: center;

      .tooltip-icon {
        margin: 0 $size-md;
      }
    }
  }

  // TOP
  .tooltip-top {
    &::after {
      border-left: $size-md solid transparent;
      border-right: $size-md solid transparent;
      border-top: $size-md solid rgba($black, 0.75);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  // BOTTOM
  .tooltip-bottom {
    &::after {
      border-left: $size-md solid transparent;
      border-right: $size-md solid transparent;
      border-bottom: $size-md solid rgba($black, 0.75);
      top: -12px;
      left: 50%;
      transform: translateY(50%);
    }
  }

  // LEFT
  .tooltip-left {
    &::after {
      border-left: $size-md solid rgba($black, 0.75);
      border-top: $size-md solid transparent;
      border-bottom: $size-md solid transparent;
      top: 50%;
      right: -8px;
      transform: translateY(-50%);
    }
  }

  // RIGHT
  .tooltip-right {
    &::after {
      border-right: $size-md solid rgba($black, 0.75);
      border-top: $size-md solid transparent;
      border-bottom: $size-md solid transparent;
      top: 50%;
      left: -8px;
      transform: translateY(-50%);
    }
  }
}

.tooltip-container:hover {
  .tooltip {
    display: block;
  }
}
