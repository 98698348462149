@import '../../../styles/constants.scss';

.wiset-search {
  display: flex;
  flex-direction: column;

  .wiset-search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;

    .wiset-search-elems {
      display: flex;
      flex-direction: column;
      flex: 0 80%;
      max-width: 80%;

      .wiset-input {
        margin: 0;
        width: 100%;
      }
      .wiset-search-results {
        z-index: 2;
        width: 100%;
        height: 1px;

        .wiset-search-results-container {
          width: 100%;
          padding: $size-md;
          border: 1px solid $gray;
          border-top: 0;
          border-radius: 0 0 $size-sm $size-sm;
          background-color: $background-gray;
          max-height: 200px;
          overflow-x: auto;
          display: flex;
          flex-direction: column;
          -webkit-box-shadow: 0 2px 6px 2px rgba($black, 0.2);
          -moz-box-shadow: 0 2px 6px 2px rgba($black, 0.2);
          box-shadow: 0 2px 6px 2px rgba($black, 0.2);

          .wiset-search-option:not(:last-child) {
            margin-bottom: $size-sm;
          }
        }
      }
      .wiset-search-results.with-info {
        margin-top: -16px;
      }
    }
    > .search-btn {
      margin-left: 1%;
      flex: 0 19%;
      max-width: 19%;
    }
  }
  .wiset-search-selected {
    margin-top: $size-md;

    .wiset-search-selected-option:not(:last-child) {
      margin-bottom: $size-sm;
    }
  }
}
