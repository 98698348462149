@import '../../../../styles/constants.scss';

.wiset-month-selector {
  > div {
    -webkit-box-shadow: 0px 0 $size-sm $size-xs rgba($black, 0.3);
    -moz-box-shadow: 0px 0 $size-sm $size-xs rgba($black, 0.3);
    box-shadow: 0px 0 $size-sm $size-xs rgba($black, 0.3);
    border-radius: $size-sm;
    background-color: $white;

    .wiset-month-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: $size-xl;
      text-align: center;

      > .btn {
        flex: 1;
        border-radius: 0;
      }

      span {
        flex: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 $size-md;
      }
    }

    .months-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .month {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 33.3333%;
        border-radius: 0;
        border: 0;
        padding: $size-md 0;
      }
      .month:not(.selected) {
        background-color: transparent;
      }

      .month:not(.tomonth):not(.selected):not(.disabled) {
        color: $black;
      }
      .month:not(.selected):hover {
        background-color: rgba($primary-light, 0.3);
      }

      .month.disabled {
        color: rgba($gray-lighter, 0.5);
        background-color: $white;
      }
    }
  }
}

@media screen and (max-width: $screen-lg) {
  .wiset-month-selector {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    > div {
      pointer-events: all;
    }
  }
}
