@import '../../../styles/constants.scss';

.btn {
  align-items: center;
  border-radius: $size-sm;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  font-family: 'Arial', sans-serif;
  font-size: $font-md;
  justify-content: center;
  outline: none;
  padding: $size-md $size-lg;
  transition: all ease-in-out 0.1s;

  i.left.with-margin {
    margin-right: $size-sm;
  }
  i.right.with-margin {
    margin-left: $size-sm;
  }
}
.btn:disabled {
  cursor: not-allowed;
  background-color: $gray-light;
  border-color: $gray-light;
}

.btn:hover:not(:disabled),
.btn:hover:not(.disabled) {
  -webkit-box-shadow: inset 0px $size-xs $size-xs 0px rgba($gray, 0.5);
  -moz-box-shadow: inset 0px $size-xs $size-xs 0px rgba($gray, 0.5);
  box-shadow: inset 0px $size-xs $size-xs 0px rgba($gray, 0.5);
}

.btn:active:not(:disabled),
.btn:active:not(.disabled) {
  transform: translateY($size-xs);
  -webkit-box-shadow: inset 0px $size-sm $size-sm 0px rgba($gray, 0.75), 0px $size-xs $size-xs 0px rgba($gray, 0.25);
  -moz-box-shadow: inset 0px $size-sm $size-sm 0px rgba($gray, 0.75), 0px $size-xs $size-xs 0px rgba($gray, 0.25);
  box-shadow: inset 0px $size-sm $size-sm 0px rgba($gray, 0.75), 0px $size-xs $size-xs 0px rgba($gray, 0.25);
}

body {
  a.btn,
  a.btn-link {
    text-decoration: none;
  }
  a.btn.btn-link:disabled,
  a.btn.btn-link.disabled {
    cursor: not-allowed;
    background-color: $gray-light;
    border-color: $gray-light;
  }
}
