@import '../../../styles/constants.scss';

.wiset-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .btn {
    > i,
    > span {
      pointer-events: none;
    }
  }

  .btn {
    height: 100%;
    width: 100%;
  }

  .dd-extra {
    background: $white;
    -webkit-box-shadow: 0px 2px 2px 0px rgba($black, 0.25);
    -moz-box-shadow: 0px 2px 2px 0px rgba($black, 0.25);
    box-shadow: 0px 2px 2px 0px rgba($black, 0.25);
    max-height: 0;
    opacity: 0;
    z-index: 10;
    pointer-events: none;

    a,
    button {
      border: 0;
      border-radius: 0;
      cursor: pointer;
    }

    > *:first-child {
      border-radius: $size-sm $size-sm 0 0;
    }
    > *:last-child {
      border-radius: 0 0 $size-sm $size-sm;
    }

    * {
      transition: linear all 0.2s;
    }

    button.white-bg:hover,
    a.white-bg:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    button:hover:not(.white-bg),
    a:hover:not(.white-bg) {
      opacity: 0.9;
    }
  }
  .dd-extra:not(.active) {
    a,
    button {
      max-height: 0;
    }
    * {
      max-height: 0;
      opacity: 0;
    }
  }
  .dd-extra.active {
    border: 1px solid $gray-light;
    max-height: 999px;
    width: max-content;
    opacity: 1;
    pointer-events: all;
    border-radius: $size-sm;

    * {
      opacity: inherit;
      max-height: 50px;
    }
  }
  .dd-extra:before {
    content: '';
    position: absolute;
    opacity: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: $white;
    border-top: 0;
    margin-left: -8px;
    margin-top: -9px;
  }
  .dd-extra.active:before {
    content: '';
    position: absolute;
    opacity: 1;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: $gray-light;
    border-top: 0;
    margin-left: -8px;
    margin-top: -9px;
  }
  .dd-extra:after {
    content: '';
    position: absolute;
    opacity: 0;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-color: $white;
    border-top: 0;
    margin-left: -0px;
    margin-top: -0px;
  }
  .dd-extra.active:after {
    content: '';
    position: absolute;
    opacity: 1;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-color: $white;
    border-top: 0;
    margin-left: -6px;
    margin-top: -6px;
  }
}
