@import '../../../../styles/constants.scss';

.wiset-search-option {
  background-color: $white;
  width: 100%;
  border: $gray-border;
  border-radius: $size-sm;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .option-image-text {
    display: flex;
    align-items: center;
    width: 80%;

    .option-image {
      margin-right: $size-md;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: $font-xl;
      }
      img {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    > p {
      font-size: $font-md;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .tooltip-container {
    max-width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .add-btn {
      padding: $size-md $size-lg;
      margin: 0;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .wiset-search-option {
    flex-direction: column;
    .option-image-text {
      width: 100%;
    }
    .add-btn {
      width: 100%;
      margin-bottom: $size-md;
    }
  }
}
