@import '../../../../styles/constants.scss';

.generic-backoffice-table {
  .wiset-table-container {
    border: 0;

    .wiset-table-header {
      .generic-backoffice-extra {
        display: flex;
      }
    }

    .wiset-table {
      tbody {
        tr {
          td {
            .generic-backoffice-no-borders {
              border: 0;
              padding: $size-sm 0;
            }
          }
        }
      }
    }
  }
}
