@import '../../styles/constants.scss';

#login-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $primary;

  .login-container {
    max-width: 400px;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $size-md;
    padding: $size-lg;
    -webkit-box-shadow: 30px 30px 15px 0 rgba(0, 0, 0, 0.11);
    -moz-box-shadow: 30px 30px 15px 0 rgba(0, 0, 0, 0.11);
    box-shadow: 30px 30px 15px 0 rgba(0, 0, 0, 0.11);

    .logo-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      h1 {
        font-weight: 400;
        font-size: $font-title;
      }

      img {
        max-width: 160px;
        width: 100%;
        object-fit: contain;
      }
    }

    .login-form {
      flex: 1;
      display: flex;
      flex-direction: column;

      .wiset-input {
        width: 100%;
        margin-bottom: $size-xl;
      }
    }

  }
}

@media screen and (max-width: $screen-md) {
  #login-page .login-container {
    max-width: 280px;
  }
}

@media screen and (max-width: $screen-xs) {
  #login-page .login-container {
    padding: 0;

    .login-form {
      align-items: center;
      .wiset-input {
        width: 80%;
        margin-bottom: $size-xl;
      }
    }
  }
}
