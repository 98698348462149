@import '../../../styles/constants.scss';

.wiset-paginator {
  display: flex;
  margin: $size-lg 0;

  .btn {
    margin: 0 5px;
  }
  .btn:first-child {
    margin-left: 0;
  }
  .btn:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: $screen-lg) {
  .wiset-paginator {
    .btn {
      margin: 0 $size-sm;
      font-size: $font-sm;
      padding: $size-md;
    }
  }
}
