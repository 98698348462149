@import '../../../styles/constants.scss';

#backoffice-product {
  flex: 1;
  .page-background {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;

    .product-card {
      flex: 1;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      background-color: $white;
      border-radius: $size-sm;
      padding: $size-md;
      -webkit-box-shadow: 0px 0px $size-sm $size-sm rgba($gray, 0.25);
      -moz-box-shadow: 0px 0px $size-sm $size-sm rgba($gray, 0.25);
      box-shadow: 0px 0px $size-sm $size-sm rgba($gray, 0.25);

      .productButton {
        margin-top: $size-lg;
        margin-bottom: $size-md;
      }
    }
  
    .search-container {
      flex: 0.5;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0 20px;
      align-self: flex-start;
  
      .product-search {
        width: 100%;
      }
  
      .result-item {
        flex: 1;
        display: flex;
        flex-direction: row;
        background-color: $white;
        border-radius: 8px;
        margin-bottom: $size-md;
  
        img {
          width: 100px;
          height: 100px;
          object-fit: contain;
        }
        
        p {
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  
    .product-images {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
  
      .image-container {
        position: relative;
        width: 200px;
        height: 260px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #e8e8e8;
        margin-bottom: $size-lg;
    
        .image-options {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0;
          padding: $size-sm 0;
          width: 50px;
          height: 50px;
          position: absolute;
          top: 0;
          right: 0;
        }
    
        .main-image {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0;
          padding: $size-sm 0;
          width: 50px;
          height: 50px;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $secondary;
          color: $white;
          border-bottom-right-radius: 8px;
        }
    
        img {
          object-fit: cover;
          width: 100%;
        }
      }

      .image-dropzone {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        background-color: $white;
        margin: $size-sm;
    
        i {
          font-size: 40px;
          color: $primary;
        }
    
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }



}