@import '../../../styles/constants.scss';

.wiset-multisearch {
  display: flex;
  flex-direction: column;

  .elements {
    display: flex;
    flex-wrap: wrap;

    .element {
      margin: $size-sm;
      padding: $size-md $size-lg;
      border-radius: $size-sm;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;

      > span {
        font-size: $font-sm;
      }
      > p {
        font-size: $font-sm;
        margin: 0;
      }

      .element-icon {
        font-size: $font-sm;
        margin-right: $size-sm;
      }

      button {
        height: $size-lg;
        width: $size-lg;
        border: 0;
        padding: 0;
        margin-left: $size-md;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          margin: 0;
          padding: 0;
          font-size: $font-sm;
        }
      }
    }
  }
  .element-info {
    color: rgba($black, 0.8);
    font-size: $font-sm;
    text-align: right;
    width: 100%;
  }

  .disabled-button {
    cursor: not-allowed;
    opacity: 0.75;

    * {
      pointer-events: none;
    }
  }
}
