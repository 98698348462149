@import '../../../styles/constants.scss';

.wiset-tabs {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: transparent;
  pointer-events: all;

  .wiset-tab:not(:first-child) {
    margin-left: $size-xs;
  }
}
