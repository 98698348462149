@import '../../../styles/constants.scss';

.wiset-table-container {
  display: flex;
  flex-direction: column;
  border-radius: $size-sm;
  border-width: 1px;
  border-style: solid;
  max-width: 100%;
  margin-left: -1px;

  .wiset-table-header {
    padding: $size-md;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    > .wiset-table-search {
      margin-right: $size-lg;
      *:not(.placeholder) {
        color: $white;
        border-color: $white;
      }
      .placeholder {
        color: rgba($white, 0.5);
      }
    }
    > .btn {
      margin-right: $size-lg;
    }

    > .wiset-selector {
      margin-right: $size-lg;
      .wiset-selector-input .wiset-input input,
      .wiset-selector-input .wiset-input.disabled input,
      .wiset-selector-arrow {
        color: $white;
        border-color: $white;
      }
      .placeholder {
        color: rgba($white, 0.5);
      }
    }
  }

  .wiset-table-overflow {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;

    .wiset-table {
      border-collapse: collapse;
      background-color: $white;
      table-layout: fixed;
      width: 100%;
      overflow: visible;

      .wiset-table-checkbox {
        .wiset-checkbox-container {
          margin: 0 auto;
        }
      }

      thead {
        th {
          padding: 0;
        }
      }
      tbody {
        tr {
          transition: linear background-color 0.2s;
        }
        tr:nth-child(even) {
          background-color: rgba($primary, 0.1);
        }

        tr.wiset-table-selectable-row {
          cursor: pointer;
        }
        tr.wiset-table-selected-row {
          background-color: rgba($primary, 0.3);
        }
        tr.wiset-table-selected-row:nth-child(even) {
          background-color: rgba($primary, 0.5);
        }

        .alert-cell,
        .loading-cell {
          padding: $size-md;

          > div.wiset-table-loading {
            padding: $size-md;
          }
        }
      }
    }
    .wiset-table-rounded {
      border-radius: 0 0 $size-sm $size-sm;
      overflow: hidden;
    }
  }

  .wiset-table-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $white;
    width: 100%;

    .wiset-table-footer-total {
      padding: $size-md;
      display: flex;
      align-items: center;
      justify-content: center;
      > p {
        margin: 0;
      }
    }

    .wiset-table-footer-paginator {
      padding: $size-md;

      .wiset-paginator {
        margin: 0;
        float: left;

        .btn {
          padding: $size-md;
          font-size: $font-sm;

          i {
            font-size: $font-xs;
          }
        }
        .btn:disabled {
          opacity: 0.5;
        }
      }
      .wiset-selector {
        float: right;
        max-width: 60px;
        background-color: $white;
        justify-self: flex-end;
        background-color: $white;
        border-radius: $size-sm;
        padding: 0 $size-sm $size-sm $size-sm;
        input {
          padding: $size-md $size-sm $size-xs $size-sm;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-md) {
  .wiset-table-container {
    .wiset-table-footer {
      .wiset-table-footer-paginator {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;

        .wiset-paginator {
          margin-bottom: $size-md;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .wiset-table-container {
    .wiset-table-header {
      flex-direction: column;

      > .wiset-table-search,
      > .btn,
      > .wiset-selector,
      > .wiset-table-header-extra {
        width: 100%;
        margin-bottom: $size-md;
        margin-right: 0;
      }

      .wiset-table-header-extra {
        > * {
          width: 100%;
        } 
      }
    }
    .wiset-table-footer {
      .wiset-table-footer-paginator {
        .wiset-paginator {
          .btn {
            padding: $size-sm;
          }
        }
      }
    }
  }
}
