@import '../../../../styles/constants.scss';

.wiset-time-selector {
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .btn {
    background-color: transparent;
    border: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    > span {
      font-size: $font-title;
    }
  }

  .hour-separator {
    font-size: $font-title;
    color: $gray;
  }
}
