@import '../../../styles/constants.scss';

#backoffice-defaultCategory-container {
  flex: 1;
  .generic-backoffice-table {

    .shop-logo-table {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }

    .wiset-table {
      tr {
        i.fa-check {
          color: $color-success;
        }
        i.fa-times {
          color: $color-error;
        }
      }
    }
  }
}
