@import '../../../styles/constants.scss';

body {
  a.btn.btn-link:disabled,
  a.btn.btn-link.disabled {
    cursor: not-allowed;
    background-color: $gray-light;
    border-color: $gray-light;
  }
}
