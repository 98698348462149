@import '../../../../styles/constants.scss';

.wiset-calendar {
  > div {
    -webkit-box-shadow: 0px 0 $size-sm $size-xs rgba($black, 0.3);
    -moz-box-shadow: 0px 0 $size-sm $size-xs rgba($black, 0.3);
    box-shadow: 0px 0 $size-sm $size-xs rgba($black, 0.3);
    border-radius: $size-sm;
    background-color: $white;

    .wiset-calendar-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 40px;
      text-align: center;

      > .btn {
        flex: 1;
        border-radius: 0;
      }

      .header-title {
        flex: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 $size-md;

        .month-selector {
          margin-right: $size-sm;
        }
        .year-selector {
          margin-left: $size-sm;
        }

        .wiset-selector-input {
          input {
            background-color: $white;
            padding: 0 $size-md 0 0;
            font-size: $font-sm;
            text-align: center;
          }
          .wiset-selector-btns {
            height: 100%;
          }
        }
        .wiset-selector-options {
          .wiset-selector-option {
            padding: $size-md $size-sm;
            font-size: $font-sm;
          }
        }
      }
    }

    .days-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: $size-md $size-md 0 $size-md;

      > div {
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 14%;
        margin: 0 0.2857%;
        padding: $size-md 0;
      }
    }

    .days-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: $size-md;

      .day {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 14%;
        margin: 1px 0.1428%;
        border-radius: 0;
        border: 0;
        padding: $size-md 0;
      }
      .day:not(.selected) {
        background-color: transparent;
      }

      .day:not(.today):not(.selected):not(.disabled) {
        color: $black;
      }
      .day:not(.selected):hover {
        background-color: rgba($primary-light, 0.3);
      }

      .day.today {
        font-weight: 600;
      }

      .day.prev-month-day:not(.selected),
      .day.next-month-day:not(.selected) {
        color: $gray;
        background-color: rgba($gray-lighter, 0.3);
      }
      .day.disabled,
      .today.day.disabled,
      .day.prev-month-day.disabled,
      .day.next-month-day.disabled {
        color: rgba($gray-lighter, 0.5);
        background-color: $white;
      }
    }
  }
}

@media screen and (max-width: $screen-lg) {
  .wiset-calendar {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    > div {
      pointer-events: all;
    }
  }
}
