@import '../../../styles/constants.scss';

.wiset-toasts-wrapper {
  padding: $size-md;
  font-size: $font-md;
  box-sizing: border-box;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 99998;

  .wiset-toast {
    display: flex;
    flex-direction: column;
    width: fit-content;
    pointer-events: all;
    color: $white;
    -webkit-box-shadow: 0px 0 $size-sm $size-xs rgba($black, 0.3);
    -moz-box-shadow: 0px 0 $size-sm $size-xs rgba($black, 0.3);
    box-shadow: 0px 0 $size-sm $size-xs rgba($black, 0.3);
    margin: $size-md;
    z-index: 99999;

    > div {
      display: flex;

      > .btn {
        background-color: transparent;
        padding-right: 0;
      }

      > .wiset-toast-content {
        padding: $size-md $size-lg;

        > .wiset-toast-text {
          margin: 0;
        }
      }
    }

    > .wiset-toast-duration {
      background-color: rgba($white, 0.3);
      height: $size-sm;
      transition-property: all;
      transition-timing-function: linear;
      transition-delay: 0s;
      animation-name: duration-width;
    }
  }
  .wiset-toast:hover {
    > .wiset-toast-duration {
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      animation-play-state: paused;
    }
  }
  .clickable-toast {
    cursor: pointer;
  }

  .wiset-toast.toast-error {
    background-color: $color-error;
  }

  .wiset-toast.toast-info {
    background-color: $color-info;
  }

  .wiset-toast.toast-success {
    background-color: $color-success;
  }

  .wiset-toast.toast-warning {
    background-color: $color-warning;
  }
}

.wiset-toasts-wrapper.bottom-center {
  flex-direction: column-reverse;
  align-items: center;

  .wiset-toast {
    transition: transform 0.6s ease-in;
    animation: toast-in-bottom 0.7s;
  }

  .hidding-toast {
    transition: transform 0.6s ease-in;
    animation: toast-out-bottom 0.7s;
  }
}

.wiset-toasts-wrapper.bottom-left {
  flex-direction: column-reverse;
  align-items: flex-start;

  .wiset-toast {
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
  }

  .hidding-toast {
    transition: transform 0.6s ease-in;
    animation: toast-out-left 0.7s;
  }
}

.wiset-toasts-wrapper.bottom-right {
  flex-direction: column-reverse;
  align-items: flex-end;

  .wiset-toast {
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
  }

  .hidding-toast {
    transition: transform 0.6s ease-in-out;
    animation: toast-out-right 0.7s;
  }
}

.wiset-toasts-wrapper.center-left {
  align-items: flex-start;
  justify-content: center;

  .wiset-toast {
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
  }

  .hidding-toast {
    transition: transform 0.6s ease-in;
    animation: toast-out-left 0.7s;
  }
}

.wiset-toasts-wrapper.center-right {
  align-items: flex-end;
  justify-content: center;

  .wiset-toast {
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
  }

  .hidding-toast {
    transition: transform 0.6s ease-in-out;
    animation: toast-out-right 0.7s;
  }
}

.wiset-toasts-wrapper.top-center {
  align-items: center;

  .wiset-toast {
    transition: transform 0.6s ease-in;
    animation: toast-in-top 0.7s;
  }

  .hidding-toast {
    transition: transform 0.6s ease-in;
    animation: toast-out-top 0.7s;
  }
}

.wiset-toasts-wrapper.top-left {
  align-items: flex-start;

  .wiset-toast {
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
  }

  .hidding-toast {
    transition: transform 0.6s ease-in;
    animation: toast-out-left 0.7s;
  }
}

.wiset-toasts-wrapper.top-right {
  align-items: flex-end;

  .wiset-toast {
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
  }

  .hidding-toast {
    transition: transform 0.6s ease-in-out;
    animation: toast-out-right 0.7s;
  }
}

@keyframes duration-width {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

// TOAST RIGHT
@keyframes toast-in-right {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes toast-out-right {
  from {
    transform: translateX(0);
    opacity: 1;
    max-height: 200px;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
    max-height: 0;
  }
}

// TOAST LEFT
@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes toast-out-left {
  from {
    transform: translateX(0);
    opacity: 1;
    max-height: 200px;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
    max-height: 0;
  }
}

// TOAST TOP
@keyframes toast-in-top {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes toast-out-top {
  from {
    transform: translateY(0);
    opacity: 1;
    max-height: 200px;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
    max-height: 0;
  }
}

// TOAST BOTTOM
@keyframes toast-in-bottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes toast-out-bottom {
  from {
    transform: translateY(0);
    opacity: 1;
    max-height: 200px;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
    max-height: 0;
  }
}
