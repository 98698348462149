@import '../../../../styles/constants.scss';

.generic-backoffice-modal {
  .json-field {
    display: flex;
    flex-direction: column;
    padding: 0 $size-sm;

    .json-text {
      display: flex;
      align-items: center;
      margin-bottom: $size-md;

      i {
        margin-right: $size-md;
        font-size: $font-md;
      }

      p {
        margin: 0;
      }
    }

    .json {
      padding: $size-md;
      background-color: $gray-lighter;
      word-break: break-word;
      border-radius: $size-sm;
      max-height: 500px;
      overflow: auto;

      pre {
        margin: 0;
        white-space: pre-line;
      }
    }
  }
}
