@import '../../../styles/constants.scss';

.wiset-modal-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100vh;
  overflow: auto;
  z-index: 1001;

  .wiset-modal-shadow {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.3);
    z-index: 1001;
  }

  .wiset-modal {
    z-index: 1002;
    max-width: $screen-lg;
    max-height: 95vh;
    width: 95%;

    .wiset-modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $size-lg;
      border-radius: $size-sm $size-sm 0 0;

      > p {
        margin: 0;
      }

      .wiset-modal-close {
        align-self: flex-end;
        padding: 0;
      }
    }
    .wiset-modal-body {
      padding: $size-lg;
      background-color: white;
    }
    .wiset-modal-body.with-header:not(.with-footer) {
      border-radius: 0 0 $size-sm $size-sm;
    }
    .wiset-modal-body.with-footer:not(.with-header) {
      border-radius: $size-sm $size-sm 0 0;
    }
    .wiset-modal-body:not(.with-footer):not(.with-header) {
      border-radius: $size-sm;
    }
    .wiset-modal-footer {
      border-top: 1px solid $gray-lighter;
      display: flex;
      flex-direction: row-reverse;
      padding: $size-md $size-lg;
      border-radius: 0 0 $size-sm $size-sm;

      > div {
        display: flex;
        flex-direction: row-reverse;
      }

      .btn {
        margin-left: $size-md;
      }
      .btn:first-child {
        margin-right: 0;
      }
      .btn:last-child {
        margin-left: 0;
      }
    }
  }
}
