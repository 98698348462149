@import '../../../../styles/constants.scss';

.wiset-table-container .wiset-table-overflow .wiset-table tbody tr td {
  border: 0;
  padding: $size-md;

  > * {
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: $font-md;
  }
}

.wiset-table-container .wiset-table-overflow .wiset-table tbody tr td.wiset-table-cell-unwrap {
  > * {
    white-space: normal;
  }
}
