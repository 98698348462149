@import '../../../../styles/constants.scss';

.generic-backoffice-filters {
  > form {
    h3 {
      margin: 0;
      padding: $size-md $size-lg;
      margin-bottom: $size-md;
      width: 100%;
      font-weight: 400;
    }
    .expand-btn {
      margin: 0;
      padding: $size-md $size-lg;
      width: 100%;
      border-radius: 0;
      font-size: $font-md;
      font-weight: bold;
    }

    .generic-min-max {
      display: flex;
      align-items: center;

      > *:first-child {
        margin-right: $size-md;
      }
      > *:last-child {
        margin-left: $size-md;
      }
    }

    > * {
      margin-bottom: $size-md;
      padding: 0 $size-lg;
    }

    .generic-backoffice-filter-buttons {
      margin-top: $size-md;
      padding: $size-md $size-lg;
      display: flex;

      > .btn {
        flex: 1;
      }
      > .btn:first-child {
        margin-right: $size-md;
      }
      > .btn:last-child {
        margin-left: $size-md;
      }
    }
  }
}

@media screen and (max-width: $screen-xl) {
  .generic-backoffice-filters {
    > form {
      .expand-btn {
        i {
          transition: linear all 0.2s;
        }
      }
      > *:not(h3):not(.expand-btn):not(.generic-backoffice-filter-buttons) {
        transition: linear opacity 0.3s, linear max-height 0.2s, linear margin 0.2s;
        max-height: 200px;
      }
      .generic-backoffice-filter-buttons {
        padding: $size-md $size-lg;
        transition: linear opacity 0.3s, linear max-height 0.2s, linear margin 0.2s, linear padding 0.3s;
      }
    }
    > form.filters-collapsed {
      .expand-btn {
        i {
          transform: rotate(180deg);
        }
      }
      > *:not(h3):not(.expand-btn):not(.generic-backoffice-filter-buttons) {
        max-height: 0;
        opacity: 0;
        margin: 0;
        overflow: hidden;
      }
      .generic-backoffice-filter-buttons {
        max-height: 0;
        opacity: 0;
        margin: 0;
        padding: 0;
      }
    }
  }
}
